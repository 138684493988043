import React from 'react';
import { graphql } from 'gatsby'

import GamesLayout from '../components/GamesLayout';
import useMatchedQuery from '../hooks/useMatchedQuery';

import ContentfulComponentSeo from '../ContentfulComponents/ContentfulComponentSeo';

import ContentfulComponentHero from '../ContentfulComponents/ContentfulComponentHero';

import ContentfulComponentIndication from '../ContentfulComponents/ContentfulComponentIndication';


export const query = graphql`
query {
 

    contentfulComponentSeo0: contentfulComponentSeo (node_locale: {eq: "es-AR"}, contentful_id: {eq: "76pjuTtdxveXKP6SPBagwz"}) {
      
  title
  type
  description {
    description
  }
  image {
    id
    title
    gatsbyImageData(
      layout: FULL_WIDTH,
      placeholder: BLURRED,
    )
  }

      internal {
        type
      }    
    }
  





    contentfulComponentHero1: contentfulComponentHero (node_locale: {eq: "es-AR"}, contentful_id: {eq: "74zHM2jnEPf0TN1bSJCU9s"}) {
      
id
title
contentful_id
node_locale
codeHero
gridModel
gridPosition
portraitGridModel
portraitGridPosition
textPosition
textColor
image {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
portraitImage {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
overlayText {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentHero2: contentfulComponentHero (node_locale: {eq: "es-AR"}, contentful_id: {eq: "2UYxzOGThLuKDOa7j0s5Y"}) {
      
id
title
contentful_id
node_locale
codeHero
gridModel
gridPosition
portraitGridModel
portraitGridPosition
textPosition
textColor
image {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
portraitImage {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
overlayText {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentIndication3: contentfulComponentIndication (node_locale: {eq: "es-AR"}, contentful_id: {eq: "5VFTJwmJKN9gddit8lWbFW"}) {
      
id
contentful_id
node_locale
title
direction
reveal {
  type
  props
}
indicationIcon {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
textImage {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
text {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentIndication4: contentfulComponentIndication (node_locale: {eq: "es-AR"}, contentful_id: {eq: "17X2Jw66S4P3RwlVxJftAw"}) {
      
id
contentful_id
node_locale
title
direction
reveal {
  type
  props
}
indicationIcon {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
textImage {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
text {
  raw
}

      internal {
        type
      }    
    }
  



 
 
 site {
  siteMetadata {
    siteUrl
  }
}}

`

const Page = (props) => {
  const seo = props.pageContext.seo;
  const matchedQuery = useMatchedQuery()
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <GamesLayout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={true} withSearch={false} >
      
      <ContentfulComponentSeo {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentSeo0" pathName="/nuevas-reglas" />
      
      <ContentfulComponentHero {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentHero1" pathName="/nuevas-reglas" />
      
      <ContentfulComponentHero {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentHero2" pathName="/nuevas-reglas" />
      
      <ContentfulComponentIndication {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentIndication3" pathName="/nuevas-reglas" />
      
      <ContentfulComponentIndication {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentIndication4" pathName="/nuevas-reglas" />
      
    </GamesLayout>
  )
}

export default Page;
